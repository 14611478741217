import React from "react"
import styled from "styled-components"
import { Grid } from "../Layout"
import { TealSection } from "../Elements"
import CardTall from "../CardTall"

const PricingWrapper = styled(TealSection)`
  .pricing-wrapper {
    padding-bottom: 3rem;

    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(290px, 1fr));
    }
    @media screen and (min-width: 1025px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .pricing-title {
      @media screen and (min-width: 768px) {
        grid-column: 1 / 3;
      }

      @media screen and (min-width: 1025px) {
        grid-column: 1 / 4;
      }
      color: var(--hlc-purple);
      text-align: center;
    }

    .card-tall {
      display: flex;
      margin: 1rem;
      @media screen and (min-width: 768px) {
        margin: 1rem 1rem 3rem;
      }
    }
  }
`

const FP04Pricing = () => {
  return (
    <PricingWrapper id="FP04Pricing">
      <Grid>
        <div className="grid-content pricing-wrapper">
          <h1 className="pricing-title">Mercenaries, For Hire.</h1>
          <CardTall
            className="card-tall pricing-card-one"
            title="Business Transformation"
            text="For established businesses, an end-to-end solution establishes the highest floor for ROI. Our full-stack strategists take the lean startup approach to solving business problems: by taking the fastest path to product market fit."
            buttonText="See Solutions"
            link="/solutions#businesstransformation"
          />
          <CardTall
            className="card-tall pricing-card-two"
            title="Staff Augmentation"
            text="Agencies and Client-side teams alike can take advantage of our staff augmentation services to build and maintain opertional momentum. Our consultant network offers best-in-class talent capable of fractional leadership (CTO/CIO), cross-disciplinary subject matter expertise, and activation SWAT teams."
            buttonText="See Availability"
            link="/solutions#staffaugmentation"
          />
          <CardTall
            className="card-tall pricing-card-three"
            title="À La Carte Services"
            text="Not sure what you need, or know exactly what you need? Start here for a consultation on your unique business challenges. Custom solutions featuring any combination of talent or expertise can be built to your project's exact specifications."
            buttonText="Get A Quote"
            link="/solutions#alacarteservices"
          />
        </div>
      </Grid>
    </PricingWrapper>
  )
}

export default FP04Pricing
