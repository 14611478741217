import React from "react"
import CardWide from "../CardWide"
import styled from "styled-components"
import { Grid } from "../Layout"
import { PurpleSection, WhiteSection } from "../Elements"

const CardsWrapper = styled.section`
  .card-wrapper {
    grid-template-rows: repeat(3, 0.25fr minmax(200px, 3fr)) 0.25fr;

    @media screen and (min-width: 800px) {
      grid-template-rows: repeat(3, 1fr minmax(200px, 3fr)) 1fr;
    }

    .bg-transition-before {
      height: 100%;
      grid-column: 1 / 4;
      grid-row: 1 / 5;
      z-index: -2;
    }
    .bg-transition-after {
      grid-column: 1 / 4;
      grid-row: 5 / 8;
      height: 100%;
      position: relative;
    }
    .bg-transition-after::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--hlc-white);
      transform-origin: top left;
      transform: skewY(-7deg);
      z-index: -1;
    }

    .card-wide {
      grid-column: 2 / 3;
      z-index: 10;
      max-width: 600px;
      margin: auto 0;
      border-radius: 0.5rem;
      box-shadow: 0.25rem 0.25rem 0.5rem rgba(var(--hlc2-navy-text), 0.34);
    }
    .card1 {
      grid-row: 2 / 3;
    }

    .card2 {
      grid-row: 4 / 5;
      justify-self: end;
    }

    .card3 {
      grid-row: 6 / 7;
    }
  }
`

const FP02Cards = () => {
  return (
    <CardsWrapper id="FP02Cards">
      <div className="card-wrapper">
        <Grid>
          <PurpleSection className="bg-transition-before" />
          <WhiteSection className="bg-transition-after" />
          <CardWide
            className="card-wide card1"
            number="01"
            title="Full Stack Talent"
            text="Everybody codes. We think being multidisciplinary necessarily begins with each individual."
            fileName="01_card_fullstacktalent_cg.jpg"
            link="/about"
          />
          <CardWide
            className="card-wide card2"
            number="02"
            title="Activation Driven"
            text="As business operators ourselves, we too understand the importance of execution. That’s why we run our own eCommerce businesses."
            fileName="02_card_activationdriven_cg.jpg"
            link="/about"
          />
          <CardWide
            className="card-wide card3"
            number="03"
            title="Technology Agnostic"
            text="We are not resellers, nor certified partners of any technology platform or framework. By design."
            fileName="03_card_technologyagnostic_cg.jpg"
            link="/about"
          />
        </Grid>
      </div>
    </CardsWrapper>
  )
}

export default FP02Cards
