import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const ButtonWrapper = styled.button`
  background: ${props =>
    props.type === "primary" ? "var(--hlc-teal)" : "var(--hlc-purple)"};
  text-transform: uppercase;
  font-family: neuzeit-grotesk, sans-serif;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 0.33rem;
  border: none;
  box-shadow: 0.25rem 0.25rem 0.5rem rgba(var(--hlc2-navy-text), 0.34);

  a {
    color: ${props =>
      props.type === "primary" ? "var(--hlc-purple)" : "var(--hlc-teal)"};
  }
`

const Button = ({ url, title, type }) => {
  return (
    <ButtonWrapper type={type}>
      <Link to={url}>{title}</Link>
    </ButtonWrapper>
  )
}

export default Button
