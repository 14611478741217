import React, { Component } from "react"
import Image from "../Image"
import styled from "styled-components"
import { Grid } from "../Layout"
import { WhiteSection, TealSection } from "../Elements"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLayerGroup,
  faFunnelDollar,
  faLaptopCode,
  faShoppingCart,
  faCodeBranch,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons"
import SolutionsBrowserBar from "../../images/frontPage/Solutions_browser_bar.svg"

const SolutionsWrapper = styled(Grid)`
  grid-template-rows: 1fr 1.5fr 0.25fr 0.25fr;

  @media screen and (min-width: 768px) {
    grid-template-rows: 1fr 3fr 0.5fr 0.5fr;
  }
  color: var(--hlc-purple);

  .solutions-info {
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    @media screen and (min-width: 768px) {
      width: 60%;
    }
  }

  .solutions-browser-wrapper {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    height: 80%;
    width: 100%;
    margin-bottom: 3rem;

    @media screen and (min-width: 800px) {
      justify-self: end;
      display: flex;
      flex-direction: row;
    }

    .solutions-list {
      flex: 1 1 40%;
      ul {
        list-style: none;
        display: flex;
        font-weight: 700;
        padding: 0;
        justify-content: space-evenly;

        @media screen and (min-width: 800px) {
          flex-direction: column;
          align-items: flex-end;
          margin-right: 1rem;
        }

        li {
          display: flex;
          align-items: center;
          line-height: 60px;

          h4 {
            margin: 0 1rem 0 0;
            display: none;

            @media screen and (min-width: 800px) {
              display: block;
            }
          }
        }
      }
    }

    .solutions-browser {
      flex: 1 1 60%;
      width: 100%;
      box-shadow: 0.25rem 0.25rem 0.5rem rgba(var(--hlc-purple-text), 0.34);
    }
  }

  .bg-chevron-before {
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    height: 100%;
    z-index: -2;
  }

  .bg-chevron-after {
    grid-column: 1 / 4;
    grid-row: 4 / 5;
    height: 100%;
    position: relative;
  }

  .bg-chevron-after::before,
  .bg-chevron-after::after {
    position: absolute;
    content: "";
    width: 50%;
    height: 100%;
    top: 0;
    background: var(--hlc-teal);
    z-index: -1;
  }

  .bg-chevron-after::before {
    left: 50%;
    transform-origin: top left;
    transform: skewY(-7deg);
  }

  .bg-chevron-after::after {
    right: 50%;
    transform-origin: top right;
    transform: skewY(7deg);
  }
`

class FP03Solutions extends Component {
  state = {
    img: "hlc_website_cropped.png",
  }
  render() {
    return (
      <SolutionsWrapper id="FP03Solutions">
        <WhiteSection className="bg-chevron-before" />
        <TealSection className="bg-chevron-after" />
        <div className="solutions-info">
          <h1>Full Service Digital Solutions</h1>
          <p>
            We offer a portfolio of capabilities that encompasses all aspects of
            Digital Strategy and Activation. Hover over the icons below to see a
            preview of some of our featured work.
          </p>
        </div>
        <div className="solutions-browser-wrapper">
          <div className="solutions-list">
            <ul>
              <li
              // onMouseEnter={() => {
              //   this.setState({
              //     img: "uwprs_homepage_cropped.jpg",
              //   })
              // }}
              >
                <h4>Experience Design</h4>{" "}
                <FontAwesomeIcon icon={faLayerGroup} size="2x" fixedWidth />
              </li>
              <li
                onMouseEnter={() => {
                  this.setState({
                    img: "hlc_website_cropped.png",
                  })
                }}
              >
                <h4>Digital Marketing</h4>{" "}
                <FontAwesomeIcon icon={faFunnelDollar} size="2x" fixedWidth />
              </li>
              <li>
                <h4>Web & App Development</h4>{" "}
                <FontAwesomeIcon icon={faLaptopCode} size="2x" fixedWidth />
              </li>
              <li>
                <h4>eCommerce Solutions</h4>{" "}
                <FontAwesomeIcon icon={faShoppingCart} size="2x" fixedWidth />
              </li>
              <li>
                <h4>DevOps</h4>{" "}
                <FontAwesomeIcon icon={faCodeBranch} size="2x" fixedWidth />
              </li>
              <li>
                <h4>And More</h4>{" "}
                <FontAwesomeIcon icon={faEllipsisH} size="2x" fixedWidth />
              </li>
            </ul>
          </div>
          <div className="solutions-browser">
            <img
              className="browser-frame"
              src={SolutionsBrowserBar}
              alt="Solutions Browser"
            />
            <Image
              className="browser-content"
              imageName={this.state.img}
              styles={{
                width: "100%",
                marginTop: "-0.5rem",
              }}
            />
          </div>
        </div>
      </SolutionsWrapper>
    )
  }
}

export default FP03Solutions
