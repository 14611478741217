import React from "react"
import { Layout, Head } from "../components/Layout"
import FP01Jumbotron from "../components/FrontPage/FP01Jumbotron"
import FP02CardInfo from "../components/FrontPage/FP02CardInfo"
import FP02Cards from "../components/FrontPage/FP02Cards"
import FP03Solutions from "../components/FrontPage/FP03Solutions"
import FP04Pricing from "../components/FrontPage/FP04Pricing"

const IndexPage = () => {
  return (
    <Layout>
      <Head title="Digital Strategy, Activated" />
      <FP01Jumbotron />
      <FP02CardInfo />
      <FP02Cards />
      <FP03Solutions />
      <FP04Pricing />
    </Layout>
  )
}

export default IndexPage
