import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import { Grid } from "../Layout"
import Button from "../Button"

const JumbotronWrapper = styled.section`
  .jumbotron {
    display: grid;
    grid-template-columns: 11fr 1fr;
    height: 100vh;
    place-items: center;

    @media screen and (min-width: 800px) {
      grid-template-columns: 1fr 1fr;
    }

    .image {
      grid-column: 1 / 3;
      grid-row: 1 / 1;
      margin: 0;
      height: 100%;
    }

    .content {
      grid-column: 1 / 2;
      grid-row: 1 / 1;
      z-index: 20;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 60%;

      .title {
        color: var(--hlc-white);
        margin: 0;
      }

      .special {
        color: var(--hlc-teal);
      }
      .desc {
        color: var(--hlc-white);
      }

      .btn-wrapper {
        display: flex;
      }
    }
  }
`

const FP01Jumbotron = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "frontPage/00_homepage_hero_cg.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const stackedBG = [
    data.file.childImageSharp.fluid,
    `linear-gradient(180deg, rgba(47, 52, 89, 0) 0%, rgba(47, 52, 89, 0.05) 5%, rgba(47, 52, 89, 0.6) 50%, #2f3459 85%, #2f3459 100%)`,
  ].reverse()

  return (
    <JumbotronWrapper id="FP01Jumbotron" className="section-banner">
      <BackgroundImage fluid={stackedBG} preserveStackingContext={true}>
        <Grid>
          <div className="grid-content">
            <div id="SectionOne" className="jumbotron">
              <div className="content">
                <h1 className="title">
                  Digital Strategy, <span className="special">Activated.</span>
                </h1>
                <p className="desc">
                  We bring lean methodology and unicorn talent together to
                  deliver beautiful and functional digital experiences. Find out
                  how we can bring your digital ambitions to life.
                </p>
                <div className="btn-wrapper">
                  <Button url="/contact" title="Get In Touch" type="primary" />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </BackgroundImage>
    </JumbotronWrapper>
  )
}

export default FP01Jumbotron
