import React from "react"
import Button from "./Button"
import styled from "styled-components"

const CardTallWrapper = styled.div`
  .card {
    background: var(--hlc-purple);
    border-radius: 0.5rem;
    box-shadow: 0.25rem 0.25rem 0.5rem rgba(var(--hlc-purple-text), 0.34);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;

    .title {
      color: var(--hlc-teal);
      margin-top: 0;
    }
  }
`

const CardTall = ({ className, title, text, buttonText, link }) => {
  return (
    <CardTallWrapper className={className}>
      <div className="card">
        <div className="caption">
          <h2 className="title">{title}</h2>
          <p className="text">{text}</p>
        </div>
        <Button url={link} title={buttonText} type="primary" />
      </div>
    </CardTallWrapper>
  )
}

export default CardTall
