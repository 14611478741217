import React from "react"
import styled from "styled-components"
import { Grid } from "../Layout"
import { PurpleSection } from "../Elements"
import Icon404 from "../../images/frontPage/404.svg"

const CardInfoWrapper = styled(PurpleSection)`
  .info-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    align-items: center;

    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 3fr 7fr;
      place-items: center;
    }

    .caption {
      color: var(--hlc-white);
      margin: 0;
      @media screen and (min-width: 768px) {
        text-align: left;
        grid-column: 2 / 3;
      }
    }

    .icon-wrapper {
      width: 60%;
      display: flex;
      @media screen and (min-width: 768px) {
        margin-top: 3rem;
        grid-column: 1 / 2;
      }
      .icon {
        filter: invert(72%) sepia(75%) saturate(360%) hue-rotate(117deg)
          brightness(82%) contrast(83%);
        width: 100%;
      }
    }
  }
`

const FP02CardInfo = () => {
  return (
    <CardInfoWrapper id="FP02CardInfo">
      <Grid>
        <div className="grid-content">
          <div className="info-wrapper">
            <div className="icon-wrapper">
              <img className="icon" src={Icon404} alt="404" />
            </div>
            <div className="caption">
              <h1>Agency Not Found</h1>
              <p>
                We are not an agency, but a collective of like-minded,
                multidisciplinary entrepreneurs who share an approach to solving
                problems. In the past decade, we have worked on all facets of
                digital activations, big and small. Here are some of the things
                that make us different.
              </p>
            </div>
          </div>
        </div>
      </Grid>
    </CardInfoWrapper>
  )
}

export default FP02CardInfo
