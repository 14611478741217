import React from "react"
import Image from "./Image"
import { Link } from "gatsby"
import styled from "styled-components"

const CardWideWrapper = styled.div`
  .card-top {
    display: grid;
    grid-template-columns: 1fr;

    .card-image {
      height: 215px;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    .card-number {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      color: var(--hlc-white);
      font-family: neuzeit-grotesk, sans-serif;
      font-weight: 700;
      font-size: 3rem;
      opacity: 0.6;
      padding: 2rem 0 0 2rem;
      z-index: 10;
    }
  }

  .card-caption {
    background: var(--hlc-purple);
    padding: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;

    h3 {
      color: var(--hlc-teal);
      margin: 0 0 1rem 0;
    }

    p {
      color: var(--hlc-white);
      margin: 0;
    }
    .card-link {
      color: var(--hlc-teal);
    }
  }
`

const CardWide = ({ className, number, title, text, fileName, link }) => {
  return (
    <CardWideWrapper className={className}>
      <Link to={link}>
        <div className="card-top">
          <div className="card-image">
            <Image
              imageName={fileName}
              styles={{
                width: "100%",
                height: "100%",
                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            />
          </div>
          <div className="card-number">{number}</div>
        </div>

        <div className="card-caption">
          <h3>{title}</h3>
          <p>
            {text}{" "}
            <Link to={link}>
              <span className="card-link">Read More.</span>
            </Link>
          </p>
        </div>
      </Link>
    </CardWideWrapper>
  )
}

export default CardWide
